import request from "@/base/util/request.js";
// 
// 查询列表
export const deleteHesuanInfoById = (params) => {
    return request({
        url: 'epidemicControl/epidemiccontrolHesuanInfo/deleteHesuanInfoById',
        method: 'get',
        params
    })
}

// GET 
//分页查询 核酸信息
export const queryHesuanInfoByPage = (data) => {
        return request({
            url: 'epidemicControl/epidemiccontrolHesuanInfo/queryHesuanInfoByPage',
            method: 'post',
            data
        })
    }
    //数据导入
export const importHesuanInfosPath = (params) => {
    return request({
        url: 'epidemicControl/epidemiccontrolHesuanInfo/importHesuanInfosPath',
        method: 'get',
        params
    })
}