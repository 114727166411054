<template>
  <div class="user-management">
    <el-row class="ctrArea">
      <el-col :span="2" :offset="19">
        <el-link
          type="primary"
          class="muban_download"
          download="核酸采集系统表.xlsx"
          href="/epidemicControl/核酸采集系统表.xlsx"
          >下载模板</el-link
        >
      </el-col>
      <el-col :span="2">
        <el-upload
          action=""
          :before-upload="beforeUpload"
          :limit="1"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          :show-file-list="false"
          ref="batchImportUploadBtn"
          :disabled="batchImportUploadIng"
          :http-request="fileuploadHandle"
        >
          <el-button
            type="primary"
            size="mini"
            :disabled="batchImportUploadIng"
            :loading="batchImportUploadIng"
            >批量导入核酸信息</el-button
          >
        </el-upload>
      </el-col>
    </el-row>

    <div style="clear: both"></div>
    <div class="search-bar">
      <el-form :inline="true" :model="listQuery" ref="departmentInfo">
        <el-form-item style="margin-left: 20px">
          <el-input
            v-model.trim="listQuery.name"
            placeholder="样本姓名"
            maxlength="32"
            style="width: 180px"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item style="margin-left: 20px">
          <el-input
            v-model.trim="listQuery.sampleNumber"
            placeholder="样本编号"
            style="width: 180px"
            maxlength="64"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item style="margin-left: 20px">
          <el-input
            v-model.trim="listQuery.contact"
            placeholder="请输入电话"
            maxlength="11"
            style="width: 180px"
            clearable
          >
          </el-input>
        </el-form-item>

        <el-form-item style="margin-left: 20px">
          <el-input
            v-model.trim="listQuery.idNumber"
            placeholder="证件号"
            maxlength="32"
            style="width: 180px"
            clearable
          >
          </el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="searchBtn">搜索</el-button>
          <el-button @click="resetBtn">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- <el-table
      :data="tableData"
      style="width: 100%"
      stripe
      :height="tableHeight"
      border
    >
      <el-table-column type="index" label="序号" width="65"> </el-table-column>
      <el-table-column prop="sampleNumber" label="样本编号" width="110">
      </el-table-column>
      <el-table-column prop="name" label="样本姓名" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="gender" label="性别" width="55"> </el-table-column>

      <el-table-column prop="dateOfBirth" label="出生日期" width="110">
      </el-table-column>
      <el-table-column prop="idType" label="证件类型" width="110">
      </el-table-column>
      <el-table-column prop="idNumber" label="证件号" width="170">
      </el-table-column>
      <el-table-column prop="contact" label="电话" width="110">
      </el-table-column>
      <el-table-column
        prop="residentialAddress"
        label="居住地址"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="registrationTime" label="登记时间" width="100">
      </el-table-column>
  <el-table-column
        prop="plannedSamplingTime"
        label="计划采样时间"
        width="110"
      >
      </el-table-column>
      <el-table-column
        prop="personnelClassification"
        label="人员分类"
        show-overflow-tooltip
      >
      </el-table-column>

      <el-table-column prop="riskLevel" label="风险等级"> </el-table-column>

      <el-table-column
        prop="crowdClassification"
        label="人群分类"
        width="100"
        show-overflow-tooltip
      >
      </el-table-column>

      <el-table-column prop="samplingPeriod" label="采样周期">
      </el-table-column>

    

      <el-table-column prop="registrar" label="登记人"> </el-table-column>
      <el-table-column prop="samplingStatus" label="采样状态">
      </el-table-column>
      <el-table-column prop="samplingTime" label="采样时间" width="110">
      </el-table-column>

      <el-table-column label="操作" fixed="right" v-slot="scope" width="80">
        <div class="option-list-box">
          <el-link
            :underline="false"
            type="danger"
            class="ml10r"
            @click="deleteOption(scope.row)"
            >删除</el-link
          >
        </div>
      </el-table-column>
    </el-table> -->
    <hesuan-table
      :table-data="tableData"
      :show-control="true"
      :localtableHeight="tableHeight"
      @deleteSuccess="getTableData"
    ></hesuan-table>
    <div class="page-box mt20r">
      <el-pagination
        background
        @current-change="getTableList"
        :pageNum-page.sync="listQuery.pageNum"
        :page-size="listQuery.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  queryHesuanInfoByPage,
  importHesuanInfosPath,
} from "../../../api/epidemiccontrolHesuanInfo/index";
import { fileupload } from "../../../api/uploadfile/index";
import hesuanTable from "./components/hesuanTable.vue";
export default {
  components: {
    hesuanTable,
  },
  data() {
    return {
      batchImportUploadIng: false, //正在处理批量上传
      listQuery: {
        pageNum: 1,
        pageSize: 10,
        name: "", //用户名
        sampleNumber: "", //样本编号
        contact: "", //电话
        idNumber: "", //证件号
      },
      total: 0,
      tableData: [],
    };
  },
  mounted() {
    this.getTableList(1);
  },

  filters: {},
  methods: {
    fileuploadHandle(params) {
      let formData = new FormData();
      formData.append("file", params.file);
      fileupload(formData)
        .then((res) => {
          if (res.code == 1) {
            console.log("成功");
            //清空已上传的文件列表（该方法不支持在 before-upload 中调用）
            this.$refs["batchImportUploadBtn"].clearFiles();
            let data = res.data;
            //告诉后台，文件已经上传成功以及文件信息，准备批量处理
            let paramers = {
              path: data.nginxFileReadPath,
            };
            importHesuanInfosPath(paramers)
              .then((res) => {
                this.batchImportUploadIng = false;

                if (res.code == 1) {
                  this.$message.success(res.msg);
                  //重新查询数据
                  this.getTableData();
                } else if (res.code == 1001) {
                  this.$message.error("数据操作错误");
                } else {
                  this.$message.error(res.msg);
                }
              })
              .catch((e) => {
                this.batchImportUploadIng = false;
                // this.$message.error(e);
              });
          } else if (res.code == -1) {
            this.batchImportUploadIng = false;
            //未登陆
            this.$router.push("/");
          } else {
            this.batchImportUploadIng = false;
            this.$message.error(res.msg);
          }
        })
        .catch((e) => {});
    },
    beforeUpload(file) {
      this.batchImportUploadIng = true;
    },
    //列表查询
    getTableList(val) {
      this.listQuery.pageNum = val;
      this.getTableData();
    },
    getTableData() {
      queryHesuanInfoByPage(this.listQuery).then((res) => {
        if (res.code == 1) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    resetBtn() {
      this.listQuery.pageNum = 1;
      this.listQuery.sampleNumber = "";
      this.listQuery.contact = "";
      this.listQuery.idNumber = "";
      this.getTableList(1);
    },
    //搜索按钮
    searchBtn() {
      this.listQuery.pages = 1;
      this.getTableList(1);
    },

  
  },
};
</script>

<style lang="less" scoped>
.user-management {
  .search-top {
    height: 40px;
    float: right;
  }

  .search-bar {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  .option-list-box {
    display: flex;
    span {
      cursor: pointer;
    }
  }
  .page-box {
    text-align: right;
  }
  .ml10r {
    margin-left: 10px;
  }
  .mt20r {
    margin-top: 20px;
  }
}
</style>