<template>
  <el-table
    :data="tableData"
    style="width: 100%"
    stripe
    :height="localtableHeight"
    border
  >
    <el-table-column type="index" label="序号" width="65"> </el-table-column>
    <el-table-column prop="sampleNumber" label="样本编号" width="110">
    </el-table-column>
    <el-table-column prop="name" label="样本姓名" show-overflow-tooltip>
    </el-table-column>
    <el-table-column prop="gender" label="性别" width="55"> </el-table-column>

    <el-table-column prop="dateOfBirth" label="出生日期" width="110">
    </el-table-column>
    <el-table-column prop="idType" label="证件类型" width="110">
    </el-table-column>
    <el-table-column prop="idNumber" label="证件号" width="170">
    </el-table-column>
    <el-table-column prop="contact" label="电话" width="110"> </el-table-column>
    <el-table-column
      prop="residentialAddress"
      label="居住地址"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column prop="registrationTime" label="登记时间" width="100">
    </el-table-column>
    <el-table-column
      prop="plannedSamplingTime"
      label="计划采样时间"
      width="110"
    >
    </el-table-column>
    <el-table-column
      prop="personnelClassification"
      label="人员分类"
      show-overflow-tooltip
    >
    </el-table-column>

    <el-table-column prop="riskLevel" label="风险等级"> </el-table-column>

    <el-table-column
      prop="crowdClassification"
      label="人群分类"
      width="100"
      show-overflow-tooltip
    >
    </el-table-column>

    <el-table-column prop="samplingPeriod" label="采样周期"> </el-table-column>
    <el-table-column prop="registrar" label="登记人"> </el-table-column>
    <el-table-column prop="samplingStatus" label="采样状态"> </el-table-column>
    <el-table-column prop="samplingTime" label="采样时间" width="110">
    </el-table-column>

    <el-table-column
      label="操作"
      fixed="right"
      v-slot="scope"
      width="80"
      v-if="showControl"
    >
      <div class="option-list-box">
        <el-link
          :underline="false"
          type="danger"
          class="ml10r"
          @click="deleteOption(scope.row)"
          >删除</el-link
        >
      </div>
    </el-table-column>
  </el-table>
</template>

<script>
import {
  deleteHesuanInfoById,
} from "../../../../api/epidemiccontrolHesuanInfo/index";
export default {
  props: {
    tableData: [],
    showControl: false, //隐藏操作区
    localtableHeight:"",
  }
 ,methods:{
     //删除
    deleteOption(row) {
      this.$confirm("确定要删除此数据", "数据删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            id: row.id,
          };
          deleteHesuanInfoById(data).then((res) => {
            if (res.code == 1) {
              this.$message({
                type: "success",
                message: "删除成功",
              });
              this.$emit("deleteSuccess")
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
 }
};
</script>

<style>
</style>